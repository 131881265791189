<template>
  <div class="wrap">
    <pageHead></pageHead>
    <div class="aboutCenter wrap">
      <el-image style="width: 100%; height: auto;" :src="require('@/assets/zxdt.jpg')" fit="contain"></el-image>
      <div class="aboutCenterWrap">
        <div class="aboutCenterBox box">
          <div class="dynamicListTop">
            <el-breadcrumb  separator="/">
              <el-breadcrumb-item :to="{ path: '/about' }">关于我们</el-breadcrumb-item>
              <el-breadcrumb-item :to="id?{path:'dynamicList'}:''" @click="getList()">动态列表</el-breadcrumb-item>
              <el-breadcrumb-item v-if="id">{{pathName}}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="dynamicListCenter" v-if="!(this.id)">
            <div v-for="(item,index) in dynamicList" :key="index" @click="toDynamicDetail(item.id)" class="dynamicListRow">
              <div class="dynamicListRowLeft">{{item.title}}</div>
              <div class="dynamicListRowRight">{{ item.create_time }}</div>
            </div>
          </div>
          <div class="dynamicListHtml" v-else >
            <h2 style="margin: 0 auto;text-align: center">{{detail.title}}</h2>
            <div style="margin: 10px auto 15px;text-align: center">{{detail.create_time}}</div>
            <div v-html="html">

            </div>
          </div>
          <div class="dynamicListBottom" v-if="!(this.id)">
            <el-pagination
                background
                @current-change="getList"
                layout="prev, pager, next"
                :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <pageFoot></pageFoot>
  </div>
</template>

<script>
import pageHead from "@/views/assembly/pageHead/pageHead";
import pageFoot from "@/views/assembly/pageFoot/pageFoot";
import {index,detail} from "@/api"
export default {
  name: "dynamicList",
  components:{
    pageHead,
    pageFoot,
  },
  data(){
    return{
      pathName:'',
      total:0,
      dynamicList:[],
      html:'',
      detail:{}
    }
  },
  computed:{
    id(){
      return this.$route.query.id
    }
  },
  mounted() {
    if (this.id){
      this.getList()
      this.getDetail(this.id)
    }else{
      this.getList()
    }
  },
  methods:{
    toDynamicDetail(e){
      this.getDetail(e)
      this.$router.push({name:"dynamicList",query:{id : e ,}})
    },
    getDetail(e){
      detail({id:e}).then(res=>{
        this.detail=res.data.data
        this.pathName=res.data.data.title
        this.html=res.data.data.content
        console.log(res)
      })
    },
    getList(e){
      var page=1
      if(e){
        page=1
      }else{
        page=e
      }
      index({page:page}).then(res=>{
        console.log(res.data.data.data)
        this.dynamicList=res.data.data.data
        this.total=res.data.data.total
        console.log(this.dynamicList)
      })
    }
  }
}
</script>

<style scoped>
.aboutCenterWrap{
  width: 100%;
  background: #F7F7F7;
}
.aboutCenterBox{
  margin-top: -5%;
  position: relative;
  background: #ffffff;
  padding: 2% 5%;
}
.dynamicListTop{
  height: 60px;
}
.dynamicListBottom{
  display: flex;
  align-items: center;
  justify-content: center;
}
.dynamicListCenter{
  border-top: 1px solid #E7E7E7;
  margin-bottom: 30px;
}
.dynamicListRow{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 20px 27px;
  border-left: 5px solid transparent;
  border-bottom: 1px solid #E7E7E7;
}
.dynamicListRowLeft{
  font-weight: 600;
}
.dynamicListRowRight{
  color: #666666;
  font-size: 16px;
}
.dynamicListRow:hover{
  border-left: 5px solid #F8AA44;
  background: #F7F7F7;
  color: #F8AA44;
  cursor: pointer;
}
.dynamicListRow:hover .dynamicListRowRight{
  color: #F8AA44;
}
.dynamicListHtml{
  padding: 0 30px;
  word-wrap:break-word;
}
.dynamicListHtml img{
  width: 100%;
}
</style>